var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "기본정보" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "소화설비명",
                              name: "fireFightingName",
                            },
                            model: {
                              value: _vm.fireFighting.fireFightingName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "fireFightingName",
                                  $$v
                                )
                              },
                              expression: "fireFighting.fireFightingName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-3 col-lg-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "관리번호",
                              name: "manageNo",
                            },
                            model: {
                              value: _vm.fireFighting.manageNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "manageNo", $$v)
                              },
                              expression: "fireFighting.manageNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-3 col-lg-3" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              disabled: _vm.isOld,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.fireFighting.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "plantCd", $$v)
                              },
                              expression: "fireFighting.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-3 col-lg-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "SOP_FIRE_FIGHTING_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "sopFireFightingTypeCd",
                              label: "설비유형",
                            },
                            model: {
                              value: _vm.fireFighting.sopFireFightingTypeCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "sopFireFightingTypeCd",
                                  $$v
                                )
                              },
                              expression: "fireFighting.sopFireFightingTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-3 col-lg-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              comboItems: _vm.kindItems,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "sopFireFightingKindCd",
                              label: "설비종류",
                            },
                            model: {
                              value: _vm.fireFighting.sopFireFightingKindCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "sopFireFightingKindCd",
                                  $$v
                                )
                              },
                              expression: "fireFighting.sopFireFightingKindCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-3 col-lg-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "수량",
                              type: "number",
                              name: "amount",
                            },
                            model: {
                              value: _vm.fireFighting.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "amount", $$v)
                              },
                              expression: "fireFighting.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-3 col-lg-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd:
                                "SOP_FIRE_FIGHTINH_REPLACEMENT_CYCLE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "교체주기",
                              name: "sopFireFightinhReplacementCycleCd",
                            },
                            model: {
                              value:
                                _vm.fireFighting
                                  .sopFireFightinhReplacementCycleCd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "sopFireFightinhReplacementCycleCd",
                                  $$v
                                )
                              },
                              expression:
                                "fireFighting.sopFireFightinhReplacementCycleCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-danger-area", {
                            attrs: {
                              editable: _vm.editable,
                              plantCd: _vm.fireFighting.plantCd,
                              label: "관련 위험물저장소",
                              name: "chmDangerMstId",
                            },
                            model: {
                              value: _vm.fireFighting.chmDangerMstId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fireFighting,
                                  "chmDangerMstId",
                                  $$v
                                )
                              },
                              expression: "fireFighting.chmDangerMstId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              editable: _vm.editable,
                              parentCheckDepts: _vm.fireFighting.deptCd,
                              plantCd: _vm.fireFighting.plantCd,
                              label: "관리부서",
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.fireFighting.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "deptCd", $$v)
                              },
                              expression: "fireFighting.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              label: "관련공정",
                              multiple: "multiple",
                              name: "processCd",
                            },
                            model: {
                              value: _vm.fireFighting.processCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "processCd", $$v)
                              },
                              expression: "fireFighting.processCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치관련 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-6 col-lg-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "설치위치",
                              name: "location",
                            },
                            model: {
                              value: _vm.fireFighting.location,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "location", $$v)
                              },
                              expression: "fireFighting.location",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-6 col-lg-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "설치일",
                              name: "installDate",
                            },
                            model: {
                              value: _vm.fireFighting.installDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "installDate", $$v)
                              },
                              expression: "fireFighting.installDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치상태 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-6 col-lg-6" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              comboItems: _vm.disposeFlagItems,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "disposeFlag",
                              label: "폐기여부",
                            },
                            model: {
                              value: _vm.fireFighting.disposeFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "disposeFlag", $$v)
                              },
                              expression: "fireFighting.disposeFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-12 col-md-6 col-lg-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: _vm.fireFighting.disposeFlag === "Y",
                              editable: _vm.editable,
                              disabled: _vm.fireFighting.disposeFlag === "N",
                              label: "폐기일",
                              name: "disposeDate",
                            },
                            model: {
                              value: _vm.fireFighting.disposeDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.fireFighting, "disposeDate", $$v)
                              },
                              expression: "fireFighting.disposeDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _vm.isOld
            ? _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    isTitle: "",
                    title: "교체이력",
                    gridHeightAuto: true,
                    columns: _vm.grid.columns,
                    data: _vm.fireFighting.replacementHistroies,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: _vm.editable,
                    isExcelDown: false,
                    isFullScreen: false,
                    selection: "multiple",
                    rowKey: "sopFireFightingReplacementHistoryId",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "customDetail"
                              ? [
                                  _c("q-btn", {
                                    attrs: {
                                      flat: "",
                                      size: "12px",
                                      color: "orange",
                                      icon: "chevron_right",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowClickDetail(
                                          props.row,
                                          props.rowIndex
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1995216146
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "추가",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addReplacementHistory },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "제외", icon: "remove" },
                                on: {
                                  btnClicked: _vm.deleteReplacementHistory,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c(
                "q-form",
                { ref: "editDialogForm" },
                [
                  _c("q-card-section", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-weight-bold-dailog q-mb-sm" },
                      [_vm._v("교체정보")]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            minuteStep: 10,
                            type: "datetime",
                            label: "교체일시",
                            name: "replacementDt",
                          },
                          model: {
                            value: _vm.selectedRow.replacementDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedRow, "replacementDt", $$v)
                            },
                            expression: "selectedRow.replacementDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "교체자",
                            name: "issuedUserId",
                          },
                          model: {
                            value: _vm.selectedRow.replacementUserId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedRow,
                                "replacementUserId",
                                $$v
                              )
                            },
                            expression: "selectedRow.replacementUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "교체내용",
                            name: "replacementContents",
                          },
                          model: {
                            value: _vm.selectedRow.replacementContents,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectedRow,
                                "replacementContents",
                                $$v
                              )
                            },
                            expression: "selectedRow.replacementContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.selectedRow.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedRow, "remarks", $$v)
                            },
                            expression: "selectedRow.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 mini-dailog-btn-impr" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "적용", icon: "check" },
                                  on: { btnClicked: _vm.acceptReplacement },
                                })
                              : _vm._e(),
                            _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "닫기",
                                icon: "close",
                              },
                              on: { btnClicked: _vm.dialogClose },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.editable
                  ? _c("c-btn", {
                      attrs: {
                        flat: "",
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.fireFighting,
                        mappingType: _vm.mappingType,
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveInfo,
                        btnCallback: _vm.saveCallback,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }